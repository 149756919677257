import React from 'react';
import { Form, Button } from 'react-bootstrap';
import CharacterCounter from './characterCounter';

interface CommentBoxProps {
  newComment: string;
  setNewComment: (comment: string) => void;
  handleAddComment: () => void;
}

const CommentBox: React.FC<CommentBoxProps> = ({
  newComment,
  setNewComment,
  handleAddComment,
}) => {
  return (
    <div>
      <Form.Control
        as='textarea'
        value={newComment}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          setNewComment(e.target.value)
        }
        placeholder='What do you think?'
        maxLength={250}
        style={{ marginBottom: '5px' }}
      />
      <CharacterCounter currentCount={newComment.length} limit={250} />
      <Button variant='primary' onClick={handleAddComment}>
        Comment
      </Button>
    </div>
  );
};

export default CommentBox;
