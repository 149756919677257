// eventDisplay.styles.ts
import { CSSProperties } from 'react';

export default function EventDisplayStyles(): {
  wrapper: CSSProperties;
  container: CSSProperties;
  carouselContainer: CSSProperties;
  carouselItem: CSSProperties;
  legend: CSSProperties;
  backButton: CSSProperties;
  arrowButtonLeft: CSSProperties;
  arrowButtonRight: CSSProperties;
  arrowButtonDown: CSSProperties;
  arrowButtonUp: CSSProperties;
  toolTipEvent: CSSProperties;
  viewPointButton: CSSProperties;
} {
  return {
    wrapper: {
      position: 'relative',
      width: '100vw',
      height: '100vh',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    carouselContainer: {
      position: 'relative',
      width: '80%', // Adjust width as per your need
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    carouselItem: {
      display: 'flex',
      marginBottom: '100px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    legend: {
      marginTop: '10px',
      color: 'grey',
    },
    backButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      cursor: 'pointer',
      padding: '5px',
    },
    arrowButtonLeft: {
      position: 'absolute',
      left: '0', // Adjust this
      top: '50%',
      transform: 'translateY(-50%)',
      border: 'none',
      background: 'none',
      padding: '0 10px', // Add padding
      cursor: 'pointer',
      color: '#000',
      zIndex: 1001,
    },
    arrowButtonRight: {
      position: 'absolute',
      right: '0', // Adjust this
      top: '50%',
      transform: 'translateY(-50%)',
      border: 'none',
      background: 'none',
      padding: '0 10px', // Add padding
      cursor: 'pointer',
      color: '#000',
      zIndex: 1001,
    },
    arrowButtonDown: {
      position: 'absolute',
      bottom: '15%', // adjust this as needed
      left: '50%',
      transform: 'translateX(-50%)',
      border: 'none',
      background: 'none',
      padding: '0',
      cursor: 'pointer',
      color: '#000',
    },
    arrowButtonUp: {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-25%)',
      border: 'none',
      background: 'none',
      padding: '0',
      cursor: 'pointer',
      color: '#000',
      zIndex: 1,
    },
    toolTipEvent: {
      position: 'absolute',
      top: '10%',
      right: '15%',
    },
    viewPointButton: {
      position: 'absolute',
      bottom: '30px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '20px',
    },
  };
}
