import React, { useState } from 'react';
import useCommentSystemContent from './useCommentSystemContent';
import CommentBox from './DiscussionComponents/commentBox';
import { CommentComponent } from './DiscussionComponents/commentComponent';
import { ConfirmationDialog } from './DiscussionComponents/confirmationDialog';
import { OpenReplies, OpenRebuttals } from './__constants__/comment.types';

interface CommentSystemProps {
  articleId: string;
  displayName: string;
}

export const CommentSystem: React.FC<CommentSystemProps> = ({
  articleId,
  displayName,
}) => {
  const {
    commentsData,
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleDeleteRebuttal,
    handleAddReply,
    handleAddRebuttal,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
    newRebuttal,
    setNewRebuttal,
    confirmDialogOpen,
    handleCloseConfirmDialog,
    handleDeleteConfirmation,
  } = useCommentSystemContent(articleId, displayName);

  const [openReplies, setOpenReplies] = useState<OpenReplies>({});
  const [openRebuttals, setOpenRebuttals] = useState<OpenRebuttals>({});
  const [showReplyBox, setShowReplyBox] = useState<{ [key: string]: boolean }>(
    {},
  );
  const [showRebuttalBox, setShowRebuttalBox] = useState<{
    [key: string]: boolean;
  }>({});

  if (!commentsData) return null;

  return (
    <div>
      <CommentBox
        newComment={newComment}
        setNewComment={setNewComment}
        handleAddComment={handleAddComment}
      />
      {sortedComments.map((commentId) => (
        <CommentComponent
          key={commentId}
          commentId={commentId}
          commentsData={commentsData}
          displayName={displayName}
          handleDeleteComment={handleDeleteComment}
          handleDeleteReply={handleDeleteReply}
          handleDeleteRebuttal={handleDeleteRebuttal}
          handleUpvote={handleUpvote}
          handleAddReply={handleAddReply}
          handleAddRebuttal={handleAddRebuttal}
          isAnonymous={!displayName || displayName === 'Anonymous'}
          openReplies={openReplies}
          setOpenReplies={setOpenReplies}
          openRebuttals={openRebuttals}
          setOpenRebuttals={setOpenRebuttals}
          showReplyBox={showReplyBox}
          setShowReplyBox={setShowReplyBox}
          showRebuttalBox={showRebuttalBox}
          setShowRebuttalBox={setShowRebuttalBox}
          newReply={newReply}
          newRebuttal={newRebuttal}
          setNewReply={setNewReply}
          setNewRebuttal={setNewRebuttal}
        />
      ))}
      <ConfirmationDialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDeleteConfirmation}
      />
    </div>
  );
};

export default CommentSystem;
