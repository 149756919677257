//dashboard.tsx
import React, { ReactNode } from 'react';
import { Container, Row } from 'react-bootstrap';
import NavbarMini from './DashboardComponents/Navigation/navbarMini';
import RightSidePanel from './DashboardComponents/rightSidePanel';
import LeftSidePanel from './DashboardComponents/leftSidePanel';
import MainContent from './DashboardComponents/mainContent';
import { useDashboard } from './dashboardProvider';
import ArchiveContent from '../../MainComponents/ArchiveFeed/archiveContent';

interface DashboardLayoutProps {
  children: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => (
  <Container
    fluid
    className='p-0 h-100 w-100 m-0'
    style={{ overflow: 'hidden' }}
  >
    <Row style={{ height: 'auto' }}>{children}</Row>
  </Container>
);

export default function Dashboard() {
  const { activeComponent } = useDashboard();

  return (
    <>
      <DashboardLayout>
        {activeComponent === 'main' ? (
          <Row className='bg-light'>
            <LeftSidePanel />
            <MainContent />
            <RightSidePanel />
          </Row>
        ) : (
          <Row className='bg-light'>
            <LeftSidePanel />
            <ArchiveContent />
            <RightSidePanel />
          </Row>
        )}
        <Row className='d-sm-none bg-light'>
          <NavbarMini />
        </Row>
      </DashboardLayout>
    </>
  );
}
