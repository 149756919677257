import React from 'react';
import styled from 'styled-components';
import { ImCross } from 'react-icons/im';

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #333;
  }
`;

const DialogTitle = styled.h3`
  margin: 0 0 20px 0;
  color: #333;
  padding-right: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button<{ variant?: 'danger' }>`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: ${(props) =>
    props.variant === 'danger' ? '#dc3545' : '#6c757d'};
  color: white;

  &:hover {
    opacity: 0.9;
  }
`;

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  if (!open) return null;

  return (
    <DialogOverlay onClick={onClose}>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <ImCross size={12} />
        </CloseButton>
        <DialogTitle>Are you sure you want to delete this?</DialogTitle>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant='danger' onClick={onConfirm}>
            Yes
          </Button>
        </ButtonContainer>
      </DialogContent>
    </DialogOverlay>
  );
};
