//navbarMini.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../../../../../App.scss';
import {
  BsFillHouseDoorFill,
  BsPeopleFill,
  BsFillBellFill,
} from 'react-icons/bs';
import { MdSettings } from 'react-icons/md';
import { SiInternetarchive } from 'react-icons/si';
import { useDashboard } from '../../dashboardProvider';

export default function NavbarMini() {
  const { setActiveComponent } = useDashboard();

  return (
    <div
      className='navmini d-flex align-items-center'
      style={{
        position: 'fixed', // Add this line
        justifyContent: 'space-between',
        padding: '0 10%',
        backgroundColor: '#f8f9fa',
        bottom: '0px',
        width: '100%',
        height: '3rem',
        borderTop: '1px solid #000',
        zIndex: 1000, // Optional, to ensure it's above other elements
      }}
    >
      <Link to='#' onClick={() => setActiveComponent('archive')}>
        <SiInternetarchive className='link' style={{ fontSize: '1.5rem' }} />
      </Link>
      <Link to='#'>
        <BsFillBellFill className='link' style={{ fontSize: '1.5rem' }} />
      </Link>
      <Link to='/dashboard' onClick={() => setActiveComponent('main')}>
        <BsFillHouseDoorFill className='link' style={{ fontSize: '1.5rem' }} />
      </Link>
      <Link to='#'>
        <BsPeopleFill className='link' style={{ fontSize: '1.5rem' }} />
      </Link>
      <Link to='/update-profile'>
        <MdSettings className='link' style={{ fontSize: '1.5rem' }} />
      </Link>
    </div>
  );
}
