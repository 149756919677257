import React from 'react';
import { Col } from 'react-bootstrap';

const SidePanelBorder = () => (
  <div style={{ borderLeft: '2px solid black', height: '100%' }}></div>
);

export default function RightSidePanel() {
  return (
    <Col xl={4} lg={3} md={0} sm={0} className='d-none d-sm-block bg-light'>
      <SidePanelBorder />
    </Col>
  );
}
