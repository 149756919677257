// App.tsx
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import AlertProvider from '../contexts/AlertContext';

// Importing CSS
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Importing components
import PrivateRoute from './PrivateRoute';
import Dashboard from './StartingScreens/PostLogin/dashboard';
import UpdateProfile from './StartingScreens/PostLogin/updateProfile';
import LandingPage from './StartingScreens/PreLogin/landingPage';
import LoginPage from './StartingScreens/PreLogin/loginPage';
import SignUpPage from './StartingScreens/PreLogin/signupScreen';
import ForgotPass from './StartingScreens/PreLogin/forgotPassword';
import EventDisplay from './MainComponents/EventDisplay/eventDisplay';
import WelcomeScreen from './StartingScreens/PostLogin/welcomeScreen';
import { DashboardProvider } from './StartingScreens/PostLogin/dashboardProvider';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <AlertProvider>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/welcome' element={<WelcomeScreen />} />
            <Route
              path='/dashboard'
              element={
                <PrivateRoute>
                  <DashboardProvider>
                    <Dashboard />
                  </DashboardProvider>
                </PrivateRoute>
              }
            />
            <Route
              path='/update-profile'
              element={
                <PrivateRoute>
                  <UpdateProfile />
                </PrivateRoute>
              }
            />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/forgot-password' element={<ForgotPass />} />
            <Route path='/event/:articleId' element={<EventDisplay />} />
          </Routes>
        </AlertProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
