import React, { useRef, useState } from 'react';
import { Form, Card, Button, Alert, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import ProfilePicture from './profilePicture';
import { HiChevronDoubleRight, HiChevronDoubleUp } from 'react-icons/hi';

interface LogoutBtnProps {
  onLogout: () => void;
}

export default function UpdateProfile() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const { logout, user, updateUserEmail, updateUserPassword } = useAuth()!;

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [cardVisible, setCardVisible] = useState(false);

  const toggleCardVisibility = () => {
    setCardVisible((prevState) => !prevState);
  };

  const navigate = useNavigate();
  const isAnonymous = user?.email === null;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const confirmPassword = confirmPasswordRef.current?.value;

    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }

    const promises = [];

    if (email !== user?.email) {
      promises.push(updateUserEmail(email!));
    }
    if (password) {
      promises.push(updateUserPassword(password));
    }

    try {
      setLoading(true);
      await Promise.all(promises);
      navigate('/dashboard');
    } catch {
      setError('Failed to Update Profile');
    } finally {
      setLoading(false);
    }
  }

  const LogoutBtn = ({ onLogout }: LogoutBtnProps) => (
    <Button variant='danger' onClick={onLogout}>
      Log Out
    </Button>
  );

  const SignUpBtn = ({ onLogout }: LogoutBtnProps) => (
    <Button variant='primary' onClick={onLogout}>
      SignUp!
    </Button>
  );

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/');
    } catch (error) {
      setError('Failed to Log Out');
    }
  }

  const renderForm = () => (
    <Form onSubmit={handleSubmit}>
      <Form.Group id='email'>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type='email'
          ref={emailRef}
          required
          defaultValue={user?.email ? user.email : ''}
        />
      </Form.Group>
      <Form.Group id='password'>
        <Form.Label>Password</Form.Label>
        <Form.Control
          type='password'
          ref={passwordRef}
          placeholder='Enter New Password'
        />
      </Form.Group>
      <Form.Group id='password-confirm'>
        <Form.Label>Password Confirmation</Form.Label>
        <Form.Control
          type='password'
          ref={confirmPasswordRef}
          required
          placeholder='Confirm Password'
        />
      </Form.Group>
      <br />
      <Button
        disabled={loading}
        className='w-100'
        variant='primary'
        type='submit'
      >
        Update
      </Button>
    </Form>
  );

  const renderCard = () => (
    <div>
      <Link
        to='#'
        onClick={(e) => {
          e.preventDefault(); // Prevent navigation
          toggleCardVisibility();
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        {cardVisible ? <HiChevronDoubleUp /> : <HiChevronDoubleRight />} Update
        Password
      </Link>
      {cardVisible && (
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30px',
            width: '25rem',
          }}
        >
          <Card.Body>
            <h2 className='text-center'>Update Account</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            {renderForm()}
          </Card.Body>
        </Card>
      )}
    </div>
  );

  const renderBackLink = () => (
    <div className='w-100 text-center mt-2'>
      <Link to='/dashboard'>Back</Link>
    </div>
  );

  return (
    <>
      <Container
        fluid
        className='d-flex justify-content-center align-items-center bg-light'
        style={{ height: '100vh', width: '100%', margin: '0' }}
      >
        {!isAnonymous ? (
          <div>
            <ProfilePicture />
            {renderCard()}
            {renderBackLink()}
            <LogoutBtn onLogout={handleLogout} />
          </div>
        ) : (
          <div>
            <h2 className='text-center'>You are not logged in</h2>
            <SignUpBtn onLogout={logout} />
          </div>
        )}
      </Container>
    </>
  );
}
