// commentSystem.styles.ts
import { CSSProperties } from 'react';

export interface Styles {
  commentWrapper: CSSProperties;
  replyWrapper: CSSProperties;
  rebuttalWrapper: CSSProperties;
  commentText: CSSProperties;
  upvoteButton: CSSProperties;
  deleteIcon: CSSProperties;
  touchableText: CSSProperties;
  touchableDiv: CSSProperties;
  primaryColor: CSSProperties;
  addReply: CSSProperties;
  replyBox: CSSProperties;
}

export const styles: Styles = {
  commentWrapper: {
    padding: '15px',
    margin: '10px 0',
    borderRadius: '8px',
    backgroundColor: '#f8f9fa',
    position: 'relative',
  },
  replyWrapper: {
    padding: '10px',
    margin: '10px 0 10px 20px',
    borderRadius: '6px',
    backgroundColor: '#e9ecef',
    position: 'relative',
    borderLeft: '3px solid #6c757d',
  },
  rebuttalWrapper: {
    padding: '10px',
    margin: '10px 0 10px 40px',
    borderRadius: '6px',
    backgroundColor: '#dee2e6',
    position: 'relative',
    borderLeft: '3px solid #495057',
  },
  commentText: {
    marginBottom: '5px',
    wordBreak: 'break-word',
  },
  upvoteButton: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#0d6efd',
    marginRight: '10px',
  },
  deleteIcon: {
    cursor: 'pointer',
    color: '#dc3545',
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  touchableText: {
    cursor: 'pointer',
    color: '#0d6efd',
    background: 'none',
    border: 'none',
    padding: '5px 0',
  },
  touchableDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '5px',
  },
  primaryColor: {
    color: '#0d6efd',
  },
  addReply: {
    marginLeft: '5px',
    color: '#0d6efd',
  },
  replyBox: {
    marginTop: '10px',
    marginBottom: '5px',
  },
};
