// CommentComponent.tsx
import React from 'react';
import { BiSolidChevronsUp } from 'react-icons/bi';
import { ImCross, ImPlus } from 'react-icons/im';
import CharacterCounter from './characterCounter';
import useCommentSystemPresentation from '../useCommentSystemPresentation';
import {
  Upvotes,
  CommentsData,
  OpenReplies,
  OpenRebuttals,
  HandleDeleteComment,
  HandleDeleteReply,
  HandleDeleteRebuttal,
  HandleUpvote,
  HandleAddReply,
  HandleAddRebuttal,
} from '../__constants__/comment.types';
import { Button, Form } from 'react-bootstrap';

interface CommentComponentProps {
  commentId: string;
  commentsData: CommentsData;
  displayName: string;
  handleDeleteComment: HandleDeleteComment;
  handleDeleteReply: HandleDeleteReply;
  handleDeleteRebuttal: HandleDeleteRebuttal;
  handleUpvote: HandleUpvote;
  handleAddReply: HandleAddReply;
  handleAddRebuttal: HandleAddRebuttal;
  isAnonymous: boolean;
  openReplies: OpenReplies;
  setOpenReplies: React.Dispatch<React.SetStateAction<OpenReplies>>;
  openRebuttals: OpenRebuttals;
  setOpenRebuttals: React.Dispatch<React.SetStateAction<OpenRebuttals>>;
  showReplyBox: { [key: string]: boolean };
  setShowReplyBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  showRebuttalBox: { [key: string]: boolean };
  setShowRebuttalBox: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
  newReply: { [key: string]: string };
  newRebuttal: { [key: string]: string };
  setNewReply: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  setNewRebuttal: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
}

export const CommentComponent: React.FC<CommentComponentProps> = ({
  commentId,
  commentsData,
  displayName,
  handleDeleteComment,
  handleDeleteReply,
  handleDeleteRebuttal,
  handleUpvote,
  handleAddReply,
  handleAddRebuttal,
  isAnonymous,
  openReplies,
  setOpenReplies,
  openRebuttals,
  setOpenRebuttals,
  showReplyBox,
  setShowReplyBox,
  showRebuttalBox,
  setShowRebuttalBox,
  newReply,
  newRebuttal,
  setNewReply,
  setNewRebuttal,
}) => {
  const { styles } = useCommentSystemPresentation();
  const comment = commentsData[commentId];

  const getUpvoteCount = (upvotes: Upvotes | undefined | null) => {
    if (!upvotes) return 0;
    return Object.keys(upvotes).length;
  };

  const canAddRebuttal = (replyAuthor: string) => {
    return (
      !isAnonymous &&
      (displayName === replyAuthor || displayName === comment.author)
    );
  };

  if (!comment) return null;

  return (
    <div key={commentId} style={styles.commentWrapper}>
      <div style={styles.commentText}>{comment.content}</div>
      <div style={styles.commentText}>By: {comment.author}</div>

      {!isAnonymous && (
        <div
          style={styles.upvoteButton}
          onClick={() => handleUpvote(commentId)}
        >
          <BiSolidChevronsUp />
          <span>({getUpvoteCount(comment.upvotes)})</span>
        </div>
      )}

      {displayName === comment.author && !isAnonymous && (
        <ImCross
          style={styles.deleteIcon}
          onClick={() => handleDeleteComment(commentId)}
        />
      )}

      {!openReplies[commentId]?.isOpen ? (
        comment.replies &&
        Object.keys(comment.replies).length > 0 && (
          <Button
            style={styles.touchableText}
            onClick={() =>
              setOpenReplies({
                ...openReplies,
                [commentId]: { isOpen: true, shownReplies: 5 },
              })
            }
          >
            View Replies
          </Button>
        )
      ) : (
        <>
          {comment.replies &&
            Object.keys(comment.replies)
              .slice(0, openReplies[commentId]?.shownReplies)
              .map((replyId) => {
                const reply = comment.replies && comment.replies[replyId];
                if (!reply) return null;
                return (
                  <div key={replyId} style={styles.replyWrapper}>
                    <div style={styles.commentText}>{reply.content}</div>
                    <div style={styles.commentText}>By: {reply.author}</div>

                    {!isAnonymous && (
                      <div
                        style={styles.upvoteButton}
                        onClick={() => handleUpvote(commentId, replyId)}
                      >
                        <BiSolidChevronsUp />
                        <span>({getUpvoteCount(reply.upvotes)})</span>
                      </div>
                    )}

                    {displayName === reply.author && !isAnonymous && (
                      <ImCross
                        style={styles.deleteIcon}
                        onClick={() => handleDeleteReply(commentId, replyId)}
                      />
                    )}

                    {/* Rebuttals Section */}
                    {!openRebuttals[replyId]?.isOpen ? (
                      reply.rebuttals &&
                      Object.keys(reply.rebuttals).length > 0 && (
                        <Button
                          style={styles.touchableText}
                          onClick={() =>
                            setOpenRebuttals({
                              ...openRebuttals,
                              [replyId]: { isOpen: true, shownRebuttals: 5 },
                            })
                          }
                        >
                          View Rebuttals
                        </Button>
                      )
                    ) : (
                      <>
                        {reply.rebuttals &&
                          Object.keys(reply.rebuttals)
                            .slice(0, openRebuttals[replyId]?.shownRebuttals)
                            .map((rebuttalId) => {
                              const rebuttal =
                                reply.rebuttals && reply.rebuttals[rebuttalId];
                              if (!rebuttal) return null;
                              return (
                                <div
                                  key={rebuttalId}
                                  style={styles.rebuttalWrapper}
                                >
                                  <div style={styles.commentText}>
                                    {rebuttal.content}
                                  </div>
                                  <div style={styles.commentText}>
                                    By: {rebuttal.author}
                                  </div>

                                  {!isAnonymous && (
                                    <div
                                      style={styles.upvoteButton}
                                      onClick={() =>
                                        handleUpvote(
                                          commentId,
                                          replyId,
                                          rebuttalId,
                                        )
                                      }
                                    >
                                      <BiSolidChevronsUp />
                                      <span>
                                        ({getUpvoteCount(rebuttal.upvotes)})
                                      </span>
                                    </div>
                                  )}

                                  {displayName === rebuttal.author &&
                                    !isAnonymous && (
                                      <ImCross
                                        style={styles.deleteIcon}
                                        onClick={() =>
                                          handleDeleteRebuttal(
                                            commentId,
                                            replyId,
                                            rebuttalId,
                                          )
                                        }
                                      />
                                    )}
                                </div>
                              );
                            })}

                        <Button
                          style={styles.touchableText}
                          onClick={() =>
                            setOpenRebuttals({
                              ...openRebuttals,
                              [replyId]: { isOpen: false, shownRebuttals: 5 },
                            })
                          }
                        >
                          Hide Rebuttals
                        </Button>
                      </>
                    )}

                    {/* Rebuttal Box */}
                    {canAddRebuttal(reply.author) && (
                      <>
                        {showRebuttalBox[replyId] ? (
                          <div>
                            <Form.Control
                              as='textarea'
                              value={newRebuttal[replyId] || ''}
                              onChange={(
                                e: React.ChangeEvent<HTMLTextAreaElement>,
                              ) =>
                                setNewRebuttal({
                                  ...newRebuttal,
                                  [replyId]: e.target.value,
                                })
                              }
                              placeholder='Add your rebuttal...'
                              maxLength={250}
                              style={styles.replyBox}
                            />
                            <CharacterCounter
                              currentCount={(newRebuttal[replyId] || '').length}
                              limit={250}
                            />
                            <Button
                              variant='primary'
                              size='sm'
                              onClick={() =>
                                handleAddRebuttal(commentId, replyId)
                              }
                            >
                              Submit Rebuttal
                            </Button>
                            <Button
                              variant='secondary'
                              size='sm'
                              onClick={() =>
                                setShowRebuttalBox({
                                  ...showRebuttalBox,
                                  [replyId]: false,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : (
                          <div
                            onClick={() =>
                              setShowRebuttalBox({
                                ...showRebuttalBox,
                                [replyId]: true,
                              })
                            }
                            style={styles.touchableDiv}
                          >
                            <ImPlus style={styles.primaryColor} />
                            <div style={styles.addReply}>Add Rebuttal</div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                );
              })}

          {showReplyBox[commentId] && !isAnonymous && (
            <div>
              <Form.Control
                as='textarea'
                value={newReply[commentId] || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewReply({ ...newReply, [commentId]: e.target.value })
                }
                placeholder='What do you think?'
                maxLength={250}
                style={styles.replyBox}
              />
              <CharacterCounter
                currentCount={(newReply[commentId] || '').length}
                limit={250}
              />
              <Button
                variant='primary'
                size='sm'
                onClick={() => handleAddReply(commentId)}
              >
                Reply
              </Button>
              <Button
                variant='secondary'
                size='sm'
                onClick={() =>
                  setShowReplyBox({ ...showReplyBox, [commentId]: false })
                }
              >
                Hide
              </Button>
            </div>
          )}
          {!showReplyBox[commentId] && !isAnonymous && (
            <div
              onClick={() =>
                setShowReplyBox({ ...showReplyBox, [commentId]: true })
              }
              style={styles.touchableDiv}
            >
              <ImPlus style={styles.primaryColor} />
              <div style={styles.addReply}>Reply</div>
            </div>
          )}
          <Button
            style={styles.touchableText}
            onClick={() =>
              setOpenReplies({
                ...openReplies,
                [commentId]: { isOpen: false, shownReplies: 5 },
              })
            }
          >
            Hide Replies
          </Button>
        </>
      )}
    </div>
  );
};
