import React from 'react';
import { Col } from 'react-bootstrap';
import NavbarFull from './Navigation/navbarFull';

const LogoTitle = () => (
  <>
    <span style={{ color: 'black', userSelect: 'none' }}>Neg</span>
    <span style={{ color: 'blue', userSelect: 'none' }}>nan</span>
  </>
);

export default function LeftSidePanel() {
  return (
    <Col
      xl={2}
      lg={3}
      md={4}
      sm={3}
      className='d-none d-sm-block bg-light border-right border-dark'
      style={{ borderRight: '2px solid black' }}
    >
      <div style={{ marginLeft: '30%', marginTop: '10%' }}>
        <h2>
          <LogoTitle />
        </h2>
        <NavbarFull />
      </div>
    </Col>
  );
}
