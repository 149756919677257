import { Canvas } from "@react-three/fiber";
import React, {
  Suspense,
  useRef,
  useState,
  FormEvent,
  MutableRefObject,
  ReactElement,
} from "react";
import { Form, Card, Button, Alert, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import Earth from "../../SharedComponents/Earth/earthLogin";

interface ForgotPassFormProps {
  handleSubmit: (e: FormEvent) => Promise<void>;
  emailRef: MutableRefObject<HTMLInputElement | null>;
  error: string;
  message: string;
  loading: boolean;
}

function ForgotPassForm({
  handleSubmit,
  emailRef,
  error,
  message,
  loading,
}: ForgotPassFormProps): ReactElement {
  return (
    <>
      <h2 className="text-center mb-4">Password Reset</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group id="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" ref={emailRef} required />
        </Form.Group>
        <br />
        <Button
          disabled={loading}
          className="w-100"
          variant="primary"
          type="submit"
        >
          Reset Password
        </Button>
      </Form>
      <div className="w-100 text-center mt-3">
        <Link to="/login">Login</Link>
      </div>
    </>
  );
}

const containerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  margin: "0",
};

const cardStyle = {
  position: "absolute" as const,
  borderRadius: "30px",
  minHeight: "18rem", // Change from height to minHeight
  width: "20rem",
  zIndex: 1,
  overflow: "auto", // Add overflow control
};

const signupTextStyle = {
  zIndex: 1,
  marginTop: "25rem",
  color: "white",
  position: "absolute" as const,
};

export default function ForgotPass(): ReactElement {
  const emailRef = useRef<HTMLInputElement | null>(null);
  const { forgotPassword } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  async function handleSubmit(e: FormEvent): Promise<void> {
    e.preventDefault();

    if (emailRef.current) {
      const email = emailRef.current.value;

      try {
        setMessage("");
        setError("");
        setLoading(true);
        await forgotPassword(email);
        setMessage("Check Inbox For Further Instructions");
      } catch (error) {
        console.log(error);
        setError("Email not found or invalid.");
      }
      setLoading(false);
    }
  }

  return (
    <>
      <Container fluid className="p-0" style={containerStyle}>
        <Card className="card bg-light text-center" style={cardStyle}>
          <Card.Body>
            <ForgotPassForm
              handleSubmit={handleSubmit}
              emailRef={emailRef}
              error={error}
              message={message}
              loading={loading}
            />
          </Card.Body>
        </Card>
        <div className="mx-auto" style={signupTextStyle}>
          Don&apos;t have an account? <Link to="/signup">Sign Up</Link>
        </div>
        <Canvas>
          <Suspense fallback={null}>
            <ambientLight />
            <spotLight />
            <Earth />
          </Suspense>
        </Canvas>
      </Container>
    </>
  );
}
