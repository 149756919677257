import {
  reload,
  updateProfile,
  updateEmail,
  updatePassword,
  User,
  signInAnonymously,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import React, { useContext, useState, useEffect } from 'react';
import { auth, fsdb, storage } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

export const AuthContext = React.createContext<AuthContextType | null>(null);

export interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (email: string, password: string, username: string) => Promise<any>;
  forgotPassword: (email: string) => Promise<void>;
  updateUserEmail: (email: string) => Promise<void>;
  updateUserPassword: (password: string) => Promise<void>;
  loginAsGuest: () => Promise<void>;
  updateUsername: (username: string) => Promise<void>;
}

export function isValidUsername(username: string): boolean {
  const usernameRegex = /^[a-zA-Z0-9]+$/;
  return usernameRegex.test(username);
}

export async function checkUsernameUnique(
  username: string | null,
): Promise<boolean> {
  if (!username) return false;
  if (!isValidUsername(username)) return false;
  const isUnique = await isUsernameUnique(username);
  return isUnique;
}

async function isUsernameUnique(username: string): Promise<boolean> {
  const displayNamesRef = collection(fsdb, 'displayNames');
  const q = query(displayNamesRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
}

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  async function signup(email: string, password: string, username: string) {
    if (!isValidUsername(username)) {
      throw new Error('Username can only contain alphabets and numbers');
    } else {
      try {
        const result = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );
        if (result.user) {
          const isUnique = await checkUsernameUnique(username);
          if (isUnique) {
            await updateProfile(result.user, { displayName: username });
            await addDoc(collection(fsdb, 'displayNames'), { username });
            const refreshedUser = auth.currentUser; // Refetch the user
            setUser(refreshedUser as User | null);
          } else {
            throw new Error('Username already exists');
          }
        }
        return result;
      } catch (error: any) {
        if (error.code === 'auth/email-already-in-use') {
          throw new Error('Email already in use');
        } else if (error.code === 'auth/invalid-email') {
          throw new Error('Invalid email');
        } else if (error.code === 'auth/weak-password') {
          throw new Error('Weak password');
        } else {
          throw new Error(error.message || 'Failed to create an account');
        }
      }
    }
  }

  function login(email: string, password: string): Promise<void> {
    return signInWithEmailAndPassword(auth, email, password).then(() => {});
  }

  function logout() {
    return auth.signOut();
  }

  function forgotPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  async function updateUserEmail(email: string) {
    if (user) {
      await updateEmail(user, email);
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  async function updateUserPassword(password: string) {
    if (user) {
      await updatePassword(user, password);
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  async function updateUsername(username: string) {
    if (user) {
      await updateProfile(user, { displayName: username });
      await reload(user);
      setUser(auth.currentUser as User | null);
    }
  }

  function loginAsGuest(): Promise<void> {
    return signInAnonymously(auth).then(() => {});
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user as User | null);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value: AuthContextType = {
    user,
    login,
    logout,
    signup,
    forgotPassword,
    updateUserEmail,
    updateUserPassword,
    updateUsername,
    loginAsGuest,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export async function upload(
  file: File,
  user: User | null,
  setLoad: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const fileRef = ref(storage, `${auth.currentUser?.uid}.png`);
  setLoad(true);

  // Upload the file
  await uploadBytes(fileRef, file);

  const photoURL = await getDownloadURL(fileRef);
  if (user) {
    await updateProfile(user, { photoURL });
    setLoad(false);
    alert('Uploaded');
  }
}
