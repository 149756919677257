import { useEffect, useState, useCallback } from 'react';
import { db } from '../../../firebase';
import { ref, set, push, onValue, remove } from 'firebase/database';
import { CommentsData } from './__constants__/content.types';

interface DeleteTarget {
  type: 'comment' | 'reply' | 'rebuttal';
  commentId: string;
  replyId?: string;
  rebuttalId?: string;
}

export default function useCommentSystemContent(
  articleId: string,
  displayName: string,
) {
  const [commentsData, setCommentsData] = useState<CommentsData | null>(null);
  const [newComment, setNewComment] = useState<string>('');
  const [newReply, setNewReply] = useState<{ [key: string]: string }>({});
  const [newRebuttal, setNewRebuttal] = useState<{ [key: string]: string }>({});
  const [replyId, setReplyId] = useState<string>('');
  const [deleteTarget, setDeleteTarget] = useState<DeleteTarget | null>(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    const commentsRef = ref(db, `comments/${articleId}`);
    const unsubscribe = onValue(
      commentsRef,
      (snapshot) => {
        setCommentsData(snapshot.val());
      },
      (error) => {
        console.error('Error reading comments: ', error);
      },
    );
    return () => {
      unsubscribe();
    };
  }, [articleId]);

  const handleAddComment = () => {
    if (newComment.trim()) {
      const newCommentRef = push(ref(db, `comments/${articleId}`));
      set(newCommentRef, {
        content: newComment,
        author: displayName || 'Anonymous',
        date: new Date().toISOString(),
        upvotes: { [displayName]: true },
        flags: 0,
      });
      setNewComment('');
    }
  };

  const handleUpvote = (
    commentId: string,
    replyId?: string,
    rebuttalId?: string,
  ) => {
    let upvoteRef;
    if (rebuttalId && replyId) {
      upvoteRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals/${rebuttalId}/upvotes/${displayName}`,
      );
    } else if (replyId) {
      upvoteRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}/upvotes/${displayName}`,
      );
    } else {
      upvoteRef = ref(
        db,
        `comments/${articleId}/${commentId}/upvotes/${displayName}`,
      );
    }
    set(upvoteRef, true).catch((error) => {
      console.error('Error upvoting:', error);
    });
  };

  const initiateDelete = useCallback((target: DeleteTarget) => {
    setDeleteTarget(target);
    setConfirmDialogOpen(true);
  }, []);

  const handleCloseConfirmDialog = useCallback(() => {
    setConfirmDialogOpen(false);
    setDeleteTarget(null);
  }, []);

  const handleDeleteConfirmation = useCallback(() => {
    if (!deleteTarget) return;

    const { type, commentId, replyId, rebuttalId } = deleteTarget;

    if (type === 'comment') {
      const commentRef = ref(db, `comments/${articleId}/${commentId}`);
      remove(commentRef).catch((error) => {
        console.error('Error deleting comment:', error);
      });
    } else if (type === 'reply' && replyId) {
      const replyRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}`,
      );
      remove(replyRef).catch((error) => {
        console.error('Error deleting reply:', error);
      });
    } else if (type === 'rebuttal' && replyId && rebuttalId) {
      const rebuttalRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals/${rebuttalId}`,
      );
      remove(rebuttalRef).catch((error) => {
        console.error('Error deleting rebuttal:', error);
      });
    }

    setConfirmDialogOpen(false);
    setDeleteTarget(null);
  }, [articleId, deleteTarget]);

  const handleDeleteComment = (commentId: string) => {
    initiateDelete({ type: 'comment', commentId });
  };

  const handleDeleteReply = (commentId: string, replyId: string) => {
    initiateDelete({ type: 'reply', commentId, replyId });
  };

  const handleDeleteRebuttal = (
    commentId: string,
    replyId: string,
    rebuttalId: string,
  ) => {
    initiateDelete({ type: 'rebuttal', commentId, replyId, rebuttalId });
  };

  const handleAddReply = (commentId: string) => {
    if (newReply[commentId]?.trim()) {
      const newReplyRef = push(
        ref(db, `comments/${articleId}/${commentId}/replies`),
      );
      set(newReplyRef, {
        content: newReply[commentId],
        author: displayName || 'Anonymous',
        date: new Date().toISOString(),
        upvotes: { [displayName]: true },
        flags: 0,
      });
      setNewReply({ ...newReply, [commentId]: '' });
    }
  };

  const handleAddRebuttal = (commentId: string, replyId: string) => {
    if (newRebuttal[replyId]?.trim()) {
      const rebuttalRef = ref(
        db,
        `comments/${articleId}/${commentId}/replies/${replyId}/rebuttals`,
      );
      const newRebuttalRef = push(rebuttalRef);
      const rebuttalData = {
        content: newRebuttal[replyId],
        author: displayName || 'Anonymous',
        date: new Date().toISOString(),
        upvotes: { [displayName]: true },
        flags: 0,
      };

      set(newRebuttalRef, rebuttalData)
        .then(() => {
          setNewRebuttal({ ...newRebuttal, [replyId]: '' });
        })
        .catch((error) => {
          console.error('Error adding rebuttal:', error);
        });
    }
  };

  const sortedComments = Object.keys(commentsData || {}).sort((a, b) => {
    const upvotesA = commentsData
      ? Object.keys(commentsData[a]?.upvotes || {}).length
      : 0;
    const upvotesB = commentsData
      ? Object.keys(commentsData[b]?.upvotes || {}).length
      : 0;
    return upvotesB - upvotesA;
  });

  return {
    commentsData,
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleDeleteRebuttal,
    handleAddReply,
    handleAddRebuttal,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    setNewReply,
    newRebuttal,
    replyId,
    setReplyId,
    setNewRebuttal,
    confirmDialogOpen,
    handleCloseConfirmDialog,
    handleDeleteConfirmation,
  };
}
