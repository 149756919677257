import React, { createContext, useContext, useState } from 'react';

type ActiveComponentType = 'main' | 'archive';

interface DashboardContextProps {
  activeComponent: ActiveComponentType;
  setActiveComponent: React.Dispatch<React.SetStateAction<ActiveComponentType>>;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined,
);

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};

interface DashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const [activeComponent, setActiveComponent] =
    useState<ActiveComponentType>('main');

  return (
    <DashboardContext.Provider value={{ activeComponent, setActiveComponent }}>
      {children}
    </DashboardContext.Provider>
  );
};
