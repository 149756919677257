// LoginPage.tsx
import React, { useRef, useState, Suspense } from "react";
import { Form, Card, Button, Alert, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import Earth from "../../SharedComponents/Earth/earthLogin";
import { Canvas } from "@react-three/fiber";
import styled from "styled-components";

const CanvasContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginCard = ({ children }: { children: React.ReactNode }) => (
  <Card
    className="card bg-light"
    style={{
      borderRadius: "30px",
      minHeight: "21rem",
      width: "20rem",
      zIndex: "1",
    }}
  >
    <Card.Body>{children}</Card.Body>
  </Card>
);

const LoginForm = ({
  onSubmit,
  emailRef,
  passwordRef,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  emailRef: React.RefObject<HTMLInputElement>;
  passwordRef: React.RefObject<HTMLInputElement>;
}) => (
  <Form onSubmit={onSubmit}>
    <Form.Group id="email">
      <Form.Label>Email</Form.Label>
      <Form.Control type="email" ref={emailRef} required />
    </Form.Group>
    <Form.Group id="password">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" ref={passwordRef} required />
    </Form.Group>
    <br />
    <Button
      className="w-100 text-center"
      variant="primary"
      type="submit"
      style={{ borderRadius: "30px" }}
    >
      Log In
    </Button>
  </Form>
);

const SignupLink = () => (
  <div
    className="mx-auto"
    style={{
      zIndex: "1",
      paddingTop: "10%",
      color: "white",
    }}
  >
    Dont have an account? <Link to="/signup">Sign Up</Link>
  </div>
);

export default function LoginPage() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { login } = useAuth();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (!email || !password) {
      setError("Email and password are required");
      return;
    }

    try {
      setError("");
      await login(email, password);
      navigate("/welcome"); // Redirect to the welcome screen instead of the dashboard
    } catch (error) {
      console.log(error);
      setError("Invalid email or password.");
    }
  }

  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          margin: "0",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CardWrapper>
              <LoginCard>
                <h2 className="text-center mb-4">Sign In</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <LoginForm
                  onSubmit={handleSubmit}
                  emailRef={emailRef}
                  passwordRef={passwordRef}
                />
                <div className="w-100 text-center mt-3">
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div>
              </LoginCard>
              <SignupLink />
            </CardWrapper>
          </div>

          <CanvasContainer>
            <Canvas>
              <Suspense fallback={null}>
                <ambientLight />
                <spotLight />
                <Earth />
              </Suspense>
            </Canvas>
          </CanvasContainer>
        </div>
      </Container>
    </>
  );
}
