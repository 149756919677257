import { CSSProperties } from 'react';

export default function ArchiveDisplayStyles(): {
  arrowButtonLeft: CSSProperties;
  arrowButtonRight: CSSProperties;
} {
  return {
    arrowButtonLeft: {
      border: 'none',
      background: 'none',
      padding: '0 10px',
      cursor: 'pointer',
      color: '#000',
    },
    arrowButtonRight: {
      border: 'none',
      background: 'none',
      padding: '0 10px',
      cursor: 'pointer',
      color: '#000',
    },
  };
}
