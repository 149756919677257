import React from 'react';
import { Col, Tab, Tabs } from 'react-bootstrap';
import FeedMain from '../../../MainComponents/MainFeed/feedMain';

// TODO: Will remove later
const TestComponent = () => <div>Sorry, This Feed Will Be Available Soon</div>;

export default function MainContent() {
  return (
    <Col
      xs={12}
      sm={9}
      md={8}
      lg={6}
      xl={6}
      className='bg-light'
      style={{ minHeight: '95vh', overflow: 'auto' }}
    >
      <Tabs
        defaultActiveKey='usNews'
        id='uncontrolled-tab-example'
        className='justify-content-center'
      >
        <Tab eventKey='usNews' title='US Feed'>
          <div style={{ marginTop: 30, marginLeft: '5%' }}>
            <FeedMain />
          </div>
        </Tab>
        <Tab eventKey='worldNews' title='World Feed'>
          <div style={{ marginTop: 30 }}>
            <TestComponent />
          </div>
        </Tab>
      </Tabs>
    </Col>
  );
}
