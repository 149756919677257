// ArchiveContent.tsx
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Col } from 'react-bootstrap';
import { Calendar, CalendarProps } from 'react-calendar';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'react-calendar/dist/Calendar.css';
import useArchiveContent from './useArchiveContent';
import useArchivePresentation from './useArchivePresentation';

type Value = CalendarProps['value'];

export default function ArchiveContent() {
  const { styles } = useArchivePresentation();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [currentSlide, setCurrentSlide] = useState(0);

  const { articles, loading, error } = selectedDate
    ? useArchiveContent(selectedDate)
    : { articles: [], loading: false, error: null };

  const handleDateChange = (value: Value) => {
    if (value instanceof Date) {
      setSelectedDate(value);
    } else {
      setSelectedDate(null);
    }
  };

  return (
    <Col
      xs={12}
      sm={9}
      md={8}
      lg={6}
      xl={6}
      className='bg-light'
      style={{
        minHeight: '95vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '5%',
      }}
    >
      <Calendar onChange={handleDateChange} value={selectedDate} />
      <div style={{ paddingTop: '10%' }}>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : articles.length === 0 ? (
          <div>Sorry, our records do not go that far back.</div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <button
                style={styles.arrowButtonLeft}
                onClick={() => setCurrentSlide(currentSlide - 1)}
                disabled={currentSlide === 0}
              >
                <FaChevronLeft size={24} />
              </button>

              <Carousel
                showArrows={false}
                selectedItem={currentSlide}
                onChange={(index) => setCurrentSlide(index)}
              >
                {articles.map((article, index) => (
                  <div key={index} style={{ paddingBottom: '5rem' }}>
                    <h5>{article.content.event}</h5>
                  </div>
                ))}
              </Carousel>

              <button
                style={styles.arrowButtonRight}
                onClick={() => setCurrentSlide(currentSlide + 1)}
                disabled={currentSlide === articles.length - 1}
              >
                <FaChevronRight size={24} />
              </button>
            </div>
          </>
        )}
      </div>
    </Col>
  );
}
